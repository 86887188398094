// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-theme-club-js": () => import("../src/pages/theme-club.js" /* webpackChunkName: "component---src-pages-theme-club-js" */),
  "component---src-templates-page-index-js": () => import("../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-pages-themes-js": () => import("../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-templates-theme-index-js": () => import("../src/templates/theme/index.js" /* webpackChunkName: "component---src-templates-theme-index-js" */),
  "component---src-templates-posts-single-js": () => import("../src/templates/posts/single.js" /* webpackChunkName: "component---src-templates-posts-single-js" */),
  "component---src-templates-posts-blog-js": () => import("../src/templates/posts/blog.js" /* webpackChunkName: "component---src-templates-posts-blog-js" */),
  "component---src-templates-users-archive-js": () => import("../src/templates/users/archive.js" /* webpackChunkName: "component---src-templates-users-archive-js" */),
  "component---src-templates-categories-archive-js": () => import("../src/templates/categories/archive.js" /* webpackChunkName: "component---src-templates-categories-archive-js" */),
  "component---src-templates-tags-archive-js": () => import("../src/templates/tags/archive.js" /* webpackChunkName: "component---src-templates-tags-archive-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

